<template>
  <div class="wrapper">
      <div class="txt-box">
          <img src="../../../assets/ic_list_zanwu_bg@2x.png" alt="">
          链接已失效
      </div>
  </div>
</template>

<script>
export default {
  components:{},
  props:{},
  data(){
    return {
    }
  },
  watch:{},
  computed:{},
  methods:{},
  created(){},
  mounted(){}
}
</script>
<style scoped>
.wrapper{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100vh;
}
.txt-box{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    font-size: 14px;
    font-family: HiraginoSansGB;
    font-weight: normal;
    color: #C8CCD5;
    margin-bottom: 200px;
}
.txt-box img{
    width: 90px;
    margin-bottom: 20px;
}
</style>